// Libraries
import React from 'react';

// Components
import LayoutContainer from 'marketing/containers/LayoutContainer';
import TitleBlock from 'marketing/components/TitleBlock';

// Styles
import styles from './styles.module.css';

export default data => {
  const {
    titleHeading,
    titleSubheading,
    pageLogo,
    imageAltText,
    pageHeading,
    pageSubheading,
    pageBannerText,
    pageBackgroundImage,
    ticketInformation,
    winnersLink,
    smallTextRules
  } = data.pageContext.data;

  const { rulesPdf } = data.pageContext;

  return (
    <LayoutContainer includeOrderTicketsCta includeWinnersLink={winnersLink}>
      <div className="theme-background-primary">
        <div className={styles.layoutContainer}>
          <TitleBlock mainHeading={titleHeading} subHeading={titleSubheading} onThemeBackground />
          <div className={styles.divider} />
          <div
            className="u-full-width u-center-canvas addon-page"
            style={pageBackgroundImage && { backgroundImage: `url( ${pageBackgroundImage} )` }}
          >
            <div className={styles.layoutContainer}>
              {pageLogo && (
                <img
                  className="u-block u-margin-center addon-page__image"
                  src={pageLogo}
                  alt={imageAltText}
                />
              )}
              {pageHeading && <p className="h2 alt u-text-shadow">{pageHeading}</p>}
              {pageBannerText && (
                <p className="h3 u-standout-heading l-margin-top">{pageBannerText}</p>
              )}
              {pageSubheading && <p className="h4 l-margin-top">{pageSubheading}</p>}
            </div>
          </div>
          {ticketInformation && (
            <div className="u-center-canvas l-padding-top">
              <div className="l-col-12 u-clearfix">
                <p className="p1 u-no-margin">{ticketInformation}</p>
              </div>
            </div>
          )}
          {smallTextRules && (
            <p className="p1 u-center-canvas l-padding-top u-no-margin">{smallTextRules}</p>
          )}
          {rulesPdf && (
            <p className="p1 u-center-canvas l-padding-top u-no-margin">
              See
              {' '}
              <a className={styles.rules} href={rulesPdf}>
                Rules
              </a>
              {' '}
              for more information.
            </p>
          )}
          <div className={styles.divider} />
        </div>
      </div>
    </LayoutContainer>
  );
};
